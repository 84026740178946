import '../App.css';
import { useParams, useNavigate, useSearchParams} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

function withParams(Component) {
  return props => <Component {...props} searchParams={useSearchParams()} params={useParams()} Navigate={useNavigate()} />;
}


class Stream extends Component {

  constructor(props){
    super(props);
    this.environmentService = new EnvironmentService();
  }

  render(){
    let params = new URLSearchParams(document.location.search);

    return(
      <div>
        <Link to={"/detail/" + this.props.params.uuid}>Back</Link>
      <div className="App">
        <video preload="metadata" type="video/mp4"src=
        {this.environmentService.getAPIEnvironmentHost() +
          "/api/Movie/Stream?movieuuid=" + this.props.params.uuid + 
          "&variant=" + params.get("variant") + 
          "&token=" + Cookies.get("key")} 
          width="750" height="500" controls id="movie">
        </video>
      </div>
      </div>
      );
  }
}

export default withParams(Stream);
