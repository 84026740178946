import logo from '../logo.svg';

import '../App.css';
import { useParams, useNavigate} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} Navigate={useNavigate()} />;
}


class ShowsSeasonEpisodes extends Component {
    // var movies = [];



  constructor(props){
    super(props);

    this.environmentService = new EnvironmentService();

    console.log("HMMMMMM " +  props.uuid);
    this.state = {
      episodes : []
    }
  }

  componentDidMount(){

    this.getMovies();
  }

  async getMovies(){
    console.log("HJERERER???")
    //http://localhost:5001/api/show/1a0d9def-3539-4e4b-8297-9390691a4e68/seasons/overview?token=2960de20-52b6-49b2-9d7d-40feb827e4de
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/show/seasons/' +this.props.params.uuid + "/episode/overview" + "?token=" + Cookies.get("key"), {
   headers: {
      'Accept': 'application/json'
   }});
   let episodesJson = await result.json();

   this.setState({
    episodes : episodesJson
   })

   console.log( episodesJson);


  }

  render(){
    const episodes = this.state.episodes;
    if(!episodes) return null;


    return(
       <div>
        <Link to={"/shows"}>Back</Link>
          <div>
            <text class="page-title">Episodes</text>
          </div>
          <div class="grid-container">
            {episodes.map((episode) =>(
              <div class="grid-item" key={episode.uuid}>
                <a href={this.environmentService.getFrontEndEnvironmentHost() + '/shows/episode/overview/' + episode.uuid} className='link-hidden'>
                  <div>
                    <text> 
                        {episode.name}<br/>
                    </text>
                  </div>
                </a>
              </div>
            ))
          }
          </div>
       </div> 
      )

  }




}

export default withParams(ShowsSeasonEpisodes);
