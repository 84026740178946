import logo from '../logo.svg';

import '../App.css';
import { useParams, useNavigate} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} Navigate={useNavigate()} />;
}


class ShowsOverview extends Component {
    // var movies = [];



  constructor(props){
    super(props);

    this.environmentService = new EnvironmentService();

    console.log("HMMMMMM " +  props.uuid);
    this.state = {
        shows : []
    }
  }

  componentDidMount(){
    console.log("This props " + this.props.params.uuid);

    this.getMovies();
  }

  async getMovies(){
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/Show/overview' + "?token=" + Cookies.get("key"), {
   headers: {
      'Accept': 'application/json'
   }});
   let showsJson = await result.json();

   this.setState({
    shows : showsJson
   })

   console.log( showsJson);


  }

  render(){
    const shows = this.state.shows;
    if(!shows) return null;


    return(
       <div>
          <div>
            <text class="page-title">Movies</text>
          </div>
          <div class="grid-container">
            {shows.map((show) =>(
              <div class="grid-item" key={show.uuid}>
                <a href={this.environmentService.getFrontEndEnvironmentHost() + '/shows/' + show.uuid} className='link-hidden'>
                  <div>
                    <text> 
                        {show.name}<br/>
                        {show.releaseDate}
                    </text>
                  </div>
                </a>
              </div>
            ))
          }
          </div>
       </div> 
      )

  }




}

export default withParams(ShowsOverview);
