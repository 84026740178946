import logo from '../logo.svg';
import us from '../us.svg';
import dk from "../dk.svg"


import '../App.css';
import { useParams, useNavigate} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';


function withParams(Component) {
  return props => <Component {...props} params={useParams()} Navigate={useNavigate()} />;
}


class ShowEpisodeOverview extends Component {
    // var movies = [];



  constructor(props){
    super(props);

    this.environmentService = new EnvironmentService();

    this.state = {
        episode : {},
        variants : []
    }
  }

    componentDidMount()
    {
        this.getEpisodeDetail();
        this.getEpisodeVariants();
    }

  async getEpisodeDetail(){
    console.log("HJERERER???")
    //http://localhost:5001/api/show/1a0d9def-3539-4e4b-8297-9390691a4e68/seasons/overview?token=2960de20-52b6-49b2-9d7d-40feb827e4de
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/show/episode/detail' + "?episodeuuid="+ this.props.params.uuid + "&token=" + Cookies.get("key"), {
   headers: {
      'Accept': 'application/json'
   }});
   let episodeJson = await result.json();

   this.setState({
    episode : episodeJson
   })

   console.log( episodeJson);
  }
async getEpisodeVariants(){
    console.log("HJERERER???")
    //http://localhost:5001/api/show/1a0d9def-3539-4e4b-8297-9390691a4e68/seasons/overview?token=2960de20-52b6-49b2-9d7d-40feb827e4de
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/show/episode/variants' + "?episodeuuid="+ this.props.params.uuid + "&token=" + Cookies.get("key"), {
   headers: {
      'Accept': 'application/json'
   }});
   let variantsJson = await result.json();

   this.setState({
    variants : variantsJson
   })

   console.log( variantsJson);
}


renderLanguageImage(imageKey){
    var imageVariant = us;
    switch(imageKey){
      case "DA":
          imageVariant = dk;
          break;
      }
      return imageVariant
    }


  render(){
    const episodedetailed = this.state.episode;
    if(!episodedetailed) return null;

    const variants = this.state.variants;
    if(!variants) return null;


    return(
        <div>
        <Link to={"/shows/season/" + episodedetailed.seasonUuid}>Back</Link>
        <div className="movieoverview"> 
        <p>{episodedetailed.name}</p>
        <div className="languages">
          {
            variants.map((episodeVariant) =>(

            <div className="inner" key={episodeVariant}>
              <div>
                <Link key={episodeVariant}  to={"/shows/episode/stream/" + this.props.params.uuid+ "?variant=" + episodeVariant }><img src={this.renderLanguageImage(episodeVariant)} width="60px" height="60px" alt="HTML tutorial"/></Link>
              </div>
            </div>
            ))
          }       
          </div> 
        </div>
      </div> 
      )

  }




}

export default withParams(ShowEpisodeOverview);
