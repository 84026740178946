import logo from '../logo.svg';

import '../App.css';
import { useParams, useNavigate} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import Cookies from 'js-cookie';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} Navigate={useNavigate()} />;
}


class RegisterMovie extends Component {
    // var movies = [];


    constructor(props){
        super(props);

        this.state = {
            NameInput : "",
            Dateinput : ""

        }
        
    this.registerMovie = this.registerMovie.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.environmentService = new EnvironmentService();


  }

  async getMovies(){
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/Language/overview' + "?token=" + Cookies.gey("key"), {
   headers: {
      'Accept': 'application/json'
   }});
   let moviesJson = await result.json();

  //  this.setState({
  //   movies : moviesJson
  //  })

   console.log( moviesJson);


  }



  handleChange(event){
    this.setState({
        keyInput : event.target.value
    });
}

  componentDidMount(){
    this.getMovies();
  }

  registerMovie(params) {
    
  }


  render(){



    return(
       <div>
          <div>
            <text class="page-title">Movies</text>
          </div>
          <div class="center">
          <form action="#" onSubmit={(event) => {this.RegisterMovie(event)}}>
                <label>
                    Movie Name
                </label><br/>
                <input type="text" value={this.state.NameInput} onChange={this.handleChange} required></input><br/>
                <label>
                    Release Date
                </label><br/>
                <input type="date" value={this.state.Dateinput} onChange={this.handleChange} required></input><br/>
                
                <button type="submit">Submit</button>

            </form>
       </div> 
       </div> 
        
      )

  }




}

export default withParams(RegisterMovie);
