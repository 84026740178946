import logo from '../logo.svg';

import '../App.css';
import { useParams, useNavigate} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} Navigate={useNavigate()} />;
}


class MoviesOverview extends Component {
    // var movies = [];



  constructor(props){
    super(props);

    this.environmentService = new EnvironmentService();

    console.log("HMMMMMM " +  props.uuid);
    this.state = {
      movies : []
    }
  }

  componentDidMount(){
    console.log("This props " + this.props.params.uuid);

    this.getMovies();
  }

  async getMovies(){
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/Movie/overview' + "?token=" + Cookies.get("key"), {
   headers: {
      'Accept': 'application/json'
   }});
   let moviesJson = await result.json();

   this.setState({
    movies : moviesJson
   })

   console.log( moviesJson);


  }

  render(){
    const movies = this.state.movies;
    if(!movies) return null;


    return(
       <div>
          <div>
            <text class="page-title">Movies</text>
          </div>
          <div class="grid-container">
            {movies.map((movie) =>(
              <div class="grid-item" key={movie.uuid}>
                <a href={this.environmentService.getFrontEndEnvironmentHost() + '/movie/detail/' + movie.uuid} className='link-hidden'>
                  <div>
                    <text> 
                        {movie.name}<br/>
                        {movie.releaseDate}
                    </text>
                  </div>
                </a>
              </div>
            ))
          }
          </div>
       </div> 
      )

  }




}

export default withParams(MoviesOverview);
