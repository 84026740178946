import logo from '../logo.svg';
import us from '../us.svg';
import dk from "../dk.svg"

import '../App.css';
import { useParams, useNavigate} from 'react-router-dom';
import { Component } from 'react';
import { EnvironmentService} from "../service/EnvironmentService";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} Navigate={useNavigate()} />;
}


class MovieDetail extends Component {
    // var movies = [];



  constructor(props){
    super(props);

    this.environmentService = new EnvironmentService();

    this.state = {
        movieVraiants : [],
        movie : {}
    }

  }

  componentDidMount(){

    this.getMoviesVariants();
    this.getMovieDetails();
  }

  async getMovieDetails(){
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/Movie/'+ this.props.params.uuid + "?token=" + Cookies.get("key"), {
      headers: {
          'Accept': 'application/json'
      }});
      let movie = await result.json();
  
      this.setState({
          movie : movie
      })
  
  }

  async getMoviesVariants(){
    var result = await fetch(this.environmentService.getAPIEnvironmentHost() + '/api/Movie/overview/variants' +"?token=" + Cookies.get("key") + "&movieuuid=" + this.props.params.uuid, {
    headers: {
        'Accept': 'application/json'
    }});
    let movieVraiants = await result.json();

    this.setState({
        movieVraiants : movieVraiants
    })
  }

  renderLanguageImage(imageKey){
    var imageVariant = us;
    switch(imageKey){
      case "DA":
          imageVariant = dk;
          break;
      }
      return imageVariant
    }

  render(){
    const moviesVariant = this.state.movieVraiants;
    if(!moviesVariant) return null;
    const movie = this.state.movie;
    if (!movie) return null;

    return(
       <div>
          <Link to={"/movies"}>Back</Link>
          <div className="movieoverview"> 
          <p>{movie.name}</p>
          <div className="languages">
            {
              moviesVariant.map((movieVariant) =>(

              <div className="inner" key={movieVariant}>
                <div>
                  <Link key={movieVariant}  to={"/movie/stream/" + this.props.params.uuid+ "?variant=" + movieVariant }><img src={this.renderLanguageImage(movieVariant)} width="60px" height="60px" alt="HTML tutorial"/></Link>
                </div>
              </div>
              ))
            }       
            </div> 
          </div>
        </div> 
      )
  }
}

export default withParams(MovieDetail);
