import '../App.css';
import React, { Component } from 'react';
import Cookies from "js-cookie";


export default class InsertKey extends Component {

    constructor(props){
        super(props);
        this.state = {
            keyInput : ""
        }

        this.registerKey = this.registerKey.bind(this);


        this.handleChange = this.handleChange.bind(this);

    }


    async registerKey(event){
        event.preventDefault();

        Cookies.set("key" , this.state.keyInput);

        alert("You have now typed in a key");
        window.location = "/movies";
    }

    handleChange(event){
        this.setState({
            keyInput : event.target.value
        });
    }

    
    render(){

        return (
            <div className="App">
                <form action="#" onSubmit={(event) => {this.registerKey(event)}}>
                    <input type="text" value={this.state.keyInput} onChange={this.handleChange} required></input>
                    <button type="submit">Submit</button>
                </form>
            </div>
        );
    }
}

