import ReactDOM from "react-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Stream from "./pages/Stream";
import MoviesOverview from "./pages/MoviesOverview";
import InsertKey from "./pages/InsertKey";
import MovieDetail from "./pages/MovieDetail";
import RegisterMovie from "./pages/RegisterMovie";
import ShowsOverview from "./pages/ShowsOverview";
import ShowsSeasonOverview from "./pages/ShowsSeasonOverview";
import ShowsSeasonEpisodes from "./pages/ShowsSeasonEpisodes";
import ShowEpisodeOverview from "./pages/ShowEpisodeOverview";
import EpisodeStream from "./pages/EpisodeStream";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path="/movies" element={<MoviesOverview />} />
          <Route exact path="/movie/stream/:uuid" element={<Stream />} />
          <Route exact path="/movie/detail/:uuid" element={<MovieDetail />} />
          <Route exact path="/movies/register" element={<RegisterMovie />} />
          <Route exact path="/shows" element={<ShowsOverview />} />
          <Route exact path="/shows/:uuid" element={<ShowsSeasonOverview />} />
          <Route exact path="/shows/season/:uuid" element={<ShowsSeasonEpisodes />} />
          <Route exact path="/shows/episode/overview/:uuid" element={<ShowEpisodeOverview />} />
          <Route exact path="/shows/episode/stream/:uuid" element={<EpisodeStream />} />
          <Route exact path="/key" element={<InsertKey />} />
          <Route path="*" element={<MoviesOverview />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
