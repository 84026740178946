


export class EnvironmentService {

    getAPIEnvironmentHost(){
        if(process.env.NODE_ENV === "development")
        {
            return "http://192.168.10.10:5001";
        }
        else 
        {
            return window.location.origin;
        }
    }
    

    getFrontEndEnvironmentHost(){
        if(process.env.NODE_ENV === "development")
        {
            return "http://192.168.10.10:3000";
        }
        else 
        {
            return window.location.origin;
        }
    }

}

